import Ellipse1171 from '@/assets/messageDemo/Ellipse1171.jpg';
import Ellipse1177 from '@/assets/messageDemo/Ellipse1177.jpg';
import Ellipse1173 from '@/assets/messageDemo/Ellipse1173.jpg';
import Ellipse1176 from '@/assets/messageDemo/Ellipse1176.jpg';
import Ellipse1174 from '@/assets/messageDemo/Ellipse1174.jpg';
import Group3466401 from '@/assets/messageDemo/Group3466401.jpg';
import Ellipse1165 from '@/assets/messageDemo/Ellipse1165.jpg';
import Ellipse1163 from '@/assets/messageDemo/Ellipse1163.jpg';
import Ellipse27 from '@/assets/messageDemo/Ellipse27.jpg';
import Ellipse22 from '@/assets/messageDemo/Ellipse22.jpg';
import Ellipse24 from '@/assets/messageDemo/Ellipse24.jpg';
import Ellipse1168 from '@/assets/messageDemo/Ellipse1168.jpg';
import Ellipse1172 from '@/assets/messageDemo/Ellipse1172.jpg';

export const jsonDataChatSession = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 10,
      length: 10,
      chat_sessions: [
        {
          title: 'Jane',
          avatar_url: Ellipse1171,
          chat_type: 1,
          last_message: 'Same here. Just watching some TV and trying to relax.',
          last_time: 1671742260,
          chat_session_id: 'de-mo-c-id-0017',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0017',
        },
        {
          title: 'Peter',
          avatar_url: Ellipse1177,
          chat_type: 1,
          last_message: 'Thanks! You too. Talk to you soon.',
          last_time: 1671709680,
          chat_session_id: 'de-mo-c-id-0016',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0016',
        },
        {
          title: 'Luna',
          avatar_url: Ellipse1173,
          chat_type: 1,
          last_message: 'You too! ',
          last_time: 1671707160,
          chat_session_id: 'de-mo-c-id-0015',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0015',
        },
        {
          title: 'Paul',
          avatar_url: Ellipse1176,
          chat_type: 1,
          last_message: 'Same. Just finished a paper for school.',
          last_time: 1671637980,
          chat_session_id: 'de-mo-c-id-0014',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0014',
        },
        {
          title: 'Tom',
          avatar_url: Ellipse1174,
          chat_type: 1,
          last_message: 'No, I missed it. What happened?',
          last_time: 1671636960,
          chat_session_id: 'de-mo-c-id-0013',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0013',
        },
        {
          title: 'talk\u0026 gossip',
          avatar_url: Group3466401,
          chat_type: 2,
          last_message: 'see ya then.',
          last_time: 1671464100,
          chat_session_id: 'de-mo-c-id-0012',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0012',
        },
        {
          title: 'Mia',
          avatar_url: Ellipse1165,
          chat_type: 1,
          last_message: "Yeah, it's going to be amazing. Can't wait!",
          last_time: 1671456420,
          chat_session_id: 'de-mo-c-id-0011',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0011',
        },
        {
          title: 'Bella',
          avatar_url: Ellipse1163,
          chat_type: 1,
          last_message: 'No problem. Let me know if you go and what you think!',
          last_time: 1671103380,
          chat_session_id: 'de-mo-c-id-0010',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0010',
        },
        {
          title: 'Cameron',
          avatar_url: Ellipse27,
          chat_type: 1,
          last_message: 'i am honestly sorry',
          last_time: 1668357660,
          chat_session_id: 'de-mo-c-id-0008',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0008',
        },
        {
          title: 'Nelia Justo',
          avatar_url: Ellipse22,
          chat_type: 1,
          last_message: 'Yeah, who said no. hahahaha😄',
          last_time: 1668273000,
          chat_session_id: 'de-mo-c-id-0009',
          unique_id: 'demo-device-dddd0001de-mo-c-id-0009',
        },
      ],
      update_time: 1697593916,
    },
  },
};

export const jsonDataMessage1 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 3,
      messages: [
        {
          avatar_url: Ellipse1171,
          send_side: 2,
          sender: 'Jane',
          type: 1,
          content: 'Same here. Just watching some TV and trying to relax.',
          attach_content: '',
          date: 1671742260,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Not much, just hanging out at home. How about you?',
          attach_content: '',
          date: 1671742200,
        },
        {
          avatar_url: Ellipse1171,
          send_side: 2,
          sender: 'Jane',
          type: 1,
          content: "Hey, what's up?",
          attach_content: '',
          date: 1671742080,
        },
      ],
    },
  },
};

export const jsonDataContacts = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      length: 12,
      contacts: [
        {
          label: 'A',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is8',
              name: 'Aden Moore',
              email: 'Kelsi22@gmail.com',
              phone: '909-826-1623',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is16',
              name: 'Alex',
              email: 'Alex47@hotmail.com',
              phone: '897-533-3515',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is6',
              name: 'Andrew Smith',
              email: 'Deon46@hotmail.com',
              phone: '370-467-0638',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is20',
              name: 'Anna',
              email: 'Anna78@gmail.com',
              phone: '897-533-3519',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is4',
              name: 'Ariana Harris',
              email: 'Bradford_Gislason@yahoo.com',
              phone: '355-807-4362',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is11',
              name: 'Axel',
              email: 'Myrtie41@hotmail.com',
              phone: '897-522-3518',
            },
          ],
        },
        {
          label: 'B',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is17',
              name: 'Bella',
              email: 'Bella1@hotmail.com',
              phone: '653-345-3516',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is9',
              name: 'Blake Mattews',
              email: 'Erwin.Schiller@gmail.com',
              phone: '464-870-9153',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is26',
              name: 'Bonnie',
              email: 'Bonnie54@hotmail.com',
              phone: '823-533-3625',
            },
          ],
        },
        {
          label: 'C',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is10',
              name: 'Cameron',
              email: 'Kellen28@hotmail.com',
              phone: '342-228-4973',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is3',
              name: 'Claire',
              email: 'Eleanore.Leannon11@hotmail.com',
              phone: '863-769-9014',
            },
          ],
        },
        {
          label: 'E',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is5',
              name: 'Esther',
              email: 'Hazel.Paucek7@hotmail.com',
              phone: '481-974-7584',
            },
          ],
        },
        {
          label: 'H',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is13',
              name: 'Hailey Levine',
              email: 'Rapha7@gmail.com',
              phone: '934-462-3698',
            },
          ],
        },
        {
          label: 'J',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is19',
              name: 'Jack',
              email: 'Jack7@gmail.com',
              phone: '897-533-3518',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is25',
              name: 'Jane',
              email: 'Jane8@gmail.com',
              phone: '823-533-3884',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is15',
              name: 'Jules',
              email: 'Jules4@hotmail.com',
              phone: '897-522-3514',
            },
          ],
        },
        {
          label: 'L',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is7',
              name: 'Liam Jordan',
              email: 'Arlo_McClure@hotmail.com',
              phone: '323-521-4922',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is14',
              name: 'Luke Adler',
              email: 'haelle78@gmail.com',
              phone: '934-462-3699',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is23',
              name: 'Luna',
              email: 'Luna6@gmail.com',
              phone: '897-533-3522',
            },
          ],
        },
        {
          label: 'M',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is18',
              name: 'Mia',
              email: 'Mia76@gmail.com',
              phone: '764-346-3577',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is27',
              name: 'Mike',
              email: 'Mike47@hotmail.com',
              phone: '865-533-3426',
            },
          ],
        },
        {
          label: 'N',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is12',
              name: 'Nelia Justo',
              email: 'Raphaelle76@gmail.com',
              phone: '934-462-3697',
            },
          ],
        },
        {
          label: 'P',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is22',
              name: 'Paul',
              email: 'Paul47@hotmail.com',
              phone: '456-832-3421',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is24',
              name: 'Peter',
              email: 'Peter7@gmail.com',
              phone: '897-533-3523',
            },
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is2',
              name: 'Peyton Dawson',
              email: 'Lela_Erdman28@gmail.com',
              phone: '889-712-9454',
            },
          ],
        },
        {
          label: 'S',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is1',
              name: 'Sean Heck',
              email: 'Rodolfo.Parker7@hotmail.com',
              phone: '376-403-7602',
            },
          ],
        },
        {
          label: 'T',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0001-demo-contact-is21',
              name: 'Tom',
              email: 'Tom4@hotmail.com',
              phone: '897-533-3520',
            },
          ],
        },
      ],
      update_time: 1697593917,
      total: 27,
    },
  },
};

export const jsonDataCalls = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 9,
      length: 9,
      calls: [
        {
          contact_name: 'Sean Heck',
          date: 1668547980,
          status: 'incoming voice',
          count: 1,
        },
        {
          contact_name: 'Peyton Dawson',
          date: 1668445020,
          status: 'outgoing voice',
          count: 3,
        },
        {
          contact_name: 'Ariana Harris',
          date: 1668362760,
          status: 'outgoing video',
          count: 7,
        },
        {
          contact_name: 'Claire',
          date: 1668359160,
          status: 'incoming video',
          count: 5,
        },
        {
          contact_name: 'Aden Moore',
          date: 1668357660,
          status: 'outgoing video',
          count: 4,
        },
        {
          contact_name: 'Blake Mattews',
          date: 1668273000,
          status: 'outgoing video',
          count: 1,
        },
        {
          contact_name: 'Esther',
          date: 1668272760,
          status: 'incoming voice',
          count: 11,
        },
        {
          contact_name: 'Andrew Smith',
          date: 1668099960,
          status: 'outgoing voice',
          count: 13,
        },
        {
          contact_name: 'Liam Jordan',
          date: 1668011940,
          status: 'incoming video',
          count: 15,
        },
      ],
      update_time: 1697593916,
    },
  },
};
export const jsonDataMessage2 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 6,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Thanks! You too. Talk to you soon.',
          attach_content: '',
          date: 1671709680,
        },
        {
          avatar_url: Ellipse1177,
          send_side: 2,
          sender: 'Peter',
          type: 1,
          content: 'Definitely! Have a great rest of your weekend.',
          attach_content: '',
          date: 1671709680,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: "Next time! We'll have to plan a beach day soon.",
          attach_content: '',
          date: 1671709560,
        },
        {
          avatar_url: Ellipse1177,
          send_side: 2,
          sender: 'Peter',
          type: 1,
          content: "I'm jealous! I wish I could have gone with you.",
          attach_content: '',
          date: 1671709560,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Yeah, it was so much fun! The water was perfect.',
          attach_content: '',
          date: 1671709440,
        },
        {
          avatar_url: Ellipse1177,
          send_side: 2,
          sender: 'Peter',
          type: 1,
          content:
            'Hey, saw your post from the beach. Looks like you had a great time!',
          attach_content: '',
          date: 1671709440,
        },
      ],
    },
  },
};
export const jsonDataMessage3 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 10,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'You too!',
          attach_content: '',
          date: 1671707160,
        },
        {
          avatar_url: Ellipse1173,
          send_side: 2,
          sender: 'Luna',
          type: 1,
          content: 'Will do. Thanks again. Have a great day!',
          attach_content: '',
          date: 1671707100,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content:
            "No problem! Let me know if you have any questions about the trail. I'd be happy to help.",
          attach_content: '',
          date: 1671706980,
        },
        {
          avatar_url: Ellipse1173,
          send_side: 2,
          sender: 'Luna',
          type: 1,
          content:
            "Thanks for the recommendation. I'll have to make a plan to go soon.",
          attach_content: '',
          date: 1671706740,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'You should definitely check it out.',
          attach_content: '',
          date: 1671706740,
        },
        {
          avatar_url: Ellipse1173,
          send_side: 2,
          sender: 'Luna',
          type: 1,
          content: "I've been wanting to try some new hiking trails.",
          attach_content: '',
          date: 1671706620,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'It was at a state park near my house.',
          attach_content: '',
          date: 1671706620,
        },
        {
          avatar_url: Ellipse1173,
          send_side: 2,
          sender: 'Luna',
          type: 1,
          content: 'Oh, that looks like a beautiful spot. Where was it?',
          attach_content: '',
          date: 1671706500,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'THX！I just took it on a hike yesterday.',
          attach_content: '',
          date: 1671706440,
        },
        {
          avatar_url: Ellipse1173,
          send_side: 2,
          sender: 'Luna',
          type: 1,
          content: 'Hey, love your new profile picture!',
          attach_content: '',
          date: 1671706320,
        },
      ],
    },
  },
};
export const jsonDataMessage4 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 3,
      messages: [
        {
          avatar_url: Ellipse1176,
          send_side: 2,
          sender: 'Paul',
          type: 1,
          content: 'Same. Just finished a paper for school.',
          attach_content: '',
          date: 1671637980,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Not much, just hanging out at home. You?',
          attach_content: '',
          date: 1671637860,
        },
        {
          avatar_url: Ellipse1176,
          send_side: 2,
          sender: 'Paul',
          type: 1,
          content: "Hey, what's up?",
          attach_content: '',
          date: 1671637740,
        },
      ],
    },
  },
};
export const jsonDataMessage5 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 3,
      messages: [
        {
          avatar_url: Ellipse1174,
          send_side: 2,
          sender: 'Tom',
          type: 1,
          content: 'No, I missed it. What happened?',
          attach_content: '',
          date: 1671636960,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Yeah, it was intense! How about you?',
          attach_content: '',
          date: 1671636900,
        },
        {
          avatar_url: Ellipse1174,
          send_side: 2,
          sender: 'Tom',
          type: 1,
          content: 'Hey, did you catch the game last night?',
          attach_content: '',
          date: 1671636660,
        },
      ],
    },
  },
};
export const jsonDataMessage6 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 7,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'see ya then.',
          attach_content: '',
          date: 1671464100,
        },
        {
          avatar_url: Ellipse1168,
          send_side: 2,
          sender: 'Jack',
          type: 1,
          content: "Cool, I'll be there.",
          attach_content: '',
          date: 1671463980,
        },
        {
          avatar_url: Ellipse1172,
          send_side: 2,
          sender: 'Anna',
          type: 1,
          content: 'Sounds good.',
          attach_content: '',
          date: 1671463860,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Come to my place! BBQ on Sunday.',
          attach_content: '',
          date: 1671463860,
        },
        {
          avatar_url: Ellipse1168,
          send_side: 2,
          sender: 'Jack',
          type: 1,
          content: 'Maybe we can hang out and do something fun?',
          attach_content: '',
          date: 1671463740,
        },
        {
          avatar_url: Ellipse1172,
          send_side: 2,
          sender: 'Anna',
          type: 1,
          content: 'Nope.',
          attach_content: '',
          date: 1671463620,
        },
        {
          avatar_url: Ellipse1168,
          send_side: 2,
          sender: 'Jack',
          type: 1,
          content: 'Guys, any plans for the weekend?',
          attach_content: '',
          date: 1671463620,
        },
      ],
    },
  },
};
export const jsonDataMessage7 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 4,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: "Yeah, it's going to be amazing. Can't wait!",
          attach_content: '',
          date: 1671456420,
        },
        {
          avatar_url: Ellipse1165,
          send_side: 2,
          sender: 'Mia',
          type: 1,
          content: "I've heard your favorite band will come.",
          attach_content: '',
          date: 1671456300,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Sure, I am so excited!',
          attach_content: '',
          date: 1671456300,
        },
        {
          avatar_url: Ellipse1165,
          send_side: 2,
          sender: 'Mia',
          type: 1,
          content: 'Hey, wanna go to the concert next week?',
          attach_content: '',
          date: 1671456180,
        },
      ],
    },
  },
};
export const jsonDataMessage8 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 4,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'No problem. Let me know if you go and what you think!',
          attach_content: '',
          date: 1671103380,
        },
        {
          avatar_url: Ellipse1163,
          send_side: 2,
          sender: 'Bella',
          type: 1,
          content: 'Thanks! I will check it out.',
          attach_content: '',
          date: 1671103260,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Yeah, The Green Room on 4th Street, it was really good!',
          attach_content: '',
          date: 1671103260,
        },
        {
          avatar_url: Ellipse1163,
          send_side: 2,
          sender: 'Bella',
          type: 1,
          content:
            'Hey, have any recommendations for a good vegan restaurant？',
          attach_content: '',
          date: 1671103140,
        },
      ],
    },
  },
};
export const jsonDataMessage9 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 5,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'i am honestly sorry',
          attach_content: '',
          date: 1668357660,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'I am sorry for not being there.',
          attach_content: '',
          date: 1668357540,
        },
        {
          avatar_url: Ellipse27,
          send_side: 2,
          sender: 'Cameron',
          type: 1,
          content: 'I am good. The wedding was finished just now.',
          attach_content: '',
          date: 1668357360,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Sup bro',
          attach_content: '',
          date: 1668357240,
        },
        {
          avatar_url: Ellipse27,
          send_side: 2,
          sender: 'Cameron',
          type: 1,
          content: 'Hey man',
          attach_content: '',
          date: 1668357240,
        },
      ],
    },
  },
};
export const jsonDataMessage10 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 8,
      messages: [
        {
          avatar_url: Ellipse22,
          send_side: 2,
          sender: 'Nelia Justo',
          type: 1,
          content: 'Yeah, who said no. hahahaha😄',
          attach_content: '',
          date: 1668273000,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: "THX for driving me back. That's a crazy party.",
          attach_content: '',
          date: 1668272760,
        },
        {
          avatar_url: Ellipse22,
          send_side: 2,
          sender: 'Nelia Justo',
          type: 1,
          content: 'Ok, see ya then.',
          attach_content: '',
          date: 1668272760,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Really? I will go definitely.',
          attach_content: '',
          date: 1668272700,
        },
        {
          avatar_url: Ellipse22,
          send_side: 2,
          sender: 'Nelia Justo',
          type: 1,
          content: 'BTW, Wyatt will come.',
          attach_content: '',
          date: 1668272460,
        },
        {
          avatar_url: Ellipse22,
          send_side: 2,
          sender: 'Nelia Justo',
          type: 1,
          content:
            'No worry, just slip out of the window and I will drive you back.',
          attach_content: '',
          date: 1668272340,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          type: 1,
          content: 'Yeah...but i am grounded. 😭',
          attach_content: '',
          date: 1668272220,
        },
        {
          avatar_url: Ellipse22,
          send_side: 2,
          sender: 'Nelia Justo',
          type: 1,
          content: 'Wanna go to the party tonight?',
          attach_content: '',
          date: 1668272220,
        },
      ],
    },
  },
};
